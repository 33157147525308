@use "styles/layout";
@use "styles/colors";
@use "./NavigationBarConstants.module.scss" as navigationBarConstants;

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        background-color: colors.$mmc-white;
        height: navigationBarConstants.$navigation-bar-height;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0 layout.$spacer-m;
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.06);
        z-index: 2; // needed to show box-shadow, it's 2 to overlap some elements which can be present on page, like map's toolbar
      }

      .leftSection {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        flex-grow: 1;
      }

      .rightSection {
        flex-shrink: 0;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: layout.$spacer-s;
      }

      .leftItem {
        margin-left: layout.$spacer-l;
      }
    }
  }
}
