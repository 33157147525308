@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;
      }

      .title {
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 600;
        line-height: 2.375rem;
        color: colors.$mmc-slate;
      }

      .description {
        font-size: 1rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.5rem;
        color: colors.$mmc-soft-slate;
      }
    }
  }
}
