@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }

      .chip {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        height: 1.5rem;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        background-color: colors.$mmc-soft-gray;
      }

      .icon {
        height: 1rem;
      }

      .active {
        background-color: colors.$mmc-green-010;
      }

      .activeIcon {
        color: colors.$mmc-green-100;
      }

      .transparent {
        .chip,
        .active {
          background-color: unset;
        }
      }

      .smallSize {
        .chip {
          height: 1.25rem;
        }

        .icon {
          height: 0.75rem;
        }
      }
    }
  }
}
