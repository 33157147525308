@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      .details {
        overflow: auto;
        margin-bottom: 0.5rem;
        max-height: 45vh;
        display: flex;
        flex-direction: column;
      }

      .paragraph {
        overflow: auto;
      }
    }
  }
}
