:global {
  body[class*="theme-"].theme {
    :local {
      .menu {
        width: 11rem !important; // prevents right menu from collapsing

        &.onboardingVisible {
          width: 14rem !important;
        }
        &.emailVisible {
          width: 14rem !important;
        }
        &.onboardingVisible.emailVisible {
          width: 16rem !important;
        }

        & svg {
          // larger icons
          width: 1.125rem;
          height: 1.125rem;
        }
      }
    }
  }
}
