@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .content {
        height: 60rem;
      }

      .emptyContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60rem;
      }

      .actionColumnTitle {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
  }
}
