@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        gap: 0.25rem;
      }

      .chip {
        display: flex;
        align-items: center;
        gap: 0.25rem;
        height: 1.5rem;
        padding: 0.5rem;
        border-radius: 0.25rem;
        background-color: colors.$mmc-soft-gray;
      }

      .chipLabel {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: colors.$mmc-slate;
      }

      .chipIcon {
        height: 1rem;
        color: colors.$mmc-slate;
      }

      .chipActive {
        background-color: colors.$mmc-green-010;

        .chipIcon {
          color: colors.$mmc-green-100;
        }
      }
    }
  }
}
