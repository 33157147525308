@use "../../../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        padding: 0 1rem 1rem;
        flex: 1;
        overflow-y: auto;
      }

      .header {
        margin-right: 2rem;
      }

      .note {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
      }

      .subTitle {
        font-size: 0.875rem;
        font-weight: 400;
        color: colors.$mmc-soft-slate;
      }

      .iconWrapper {
        width: 1.25rem;
        height: 1.25rem;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }

      .entityTypeIcon {
        width: 0.5rem;
      }

      .accounts {
        background-color: colors.$mmc-primary-100;
      }

      .contacts {
        background-color: colors.$mmc-green-100;
      }

      .deals {
        background-color: colors.$mmc-red-100;
      }

      .footer {
        background: colors.$mmc-soft-gray;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.5rem;
        padding: 1rem 1.5rem 1rem 1.25rem;
        user-select: none;
        width: 100%;
      }

      .footerInfo {
        display: flex;
        gap: 0.5rem;
        justify-content: center;
      }

      .clockIcon {
        width: 1.5rem;
        color: colors.$mmc-disabled-gray;
      }

      .footerInfoText {
        color: colors.$mmc-soft-slate;
        font-weight: 500;
      }

      .footerInfoTextDateTime {
        font-style: italic;
      }

      .modalTitle {
        flex-grow: 1;
      }

      .modal {
        :global(.ant-modal-body) {
          padding: 0;
          height: 100%;
          overflow-x: hidden;
          flex: 1;
          display: flex;
          flex-direction: column;
          min-height: 0;
        }
        & :global(.ant-modal-content) {
          max-height: 80vh;
          display: flex;
          flex-direction: column;
        }
      }

      .form {
        height: 100%;
        min-height: 0;
        display: flex;
        flex: 1;
        flex-direction: column;
      }

      .closeConfirmation {
        width: 16rem;
      }

      .formWrapper {
        flex: 1;
        min-height: 0;
        display: flex;
        height: 100%;
        flex-direction: column;
      }

      .backLink {
        color: colors.$mmc-primary;
        cursor: pointer;
      }
    }
  }
}
