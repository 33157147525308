@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .sendingContainer {
        display: flex;
        align-items: center;
        gap: 0.25rem;
      }
    }
  }
}
