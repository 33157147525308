@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .panelsContainer {
        display: flex;
        gap: 0.75rem;
        height: 32rem;
      }

      .activitiesPanel {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        width: 20rem;
      }

      .activityPanel {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        flex-shrink: 1;
        border: 1px solid #{colors.$mmc-accent-gray};
        border-radius: 4px;
        padding: 0.75rem;
        overflow: auto;
      }

      .activityView {
        height: 100%;
      }

      .table {
        flex-grow: 1;
        width: 100%;

        :global(.ant-table) {
          border-color: colors.$mmc-accent-gray !important;
        }
      }

      .row {
        cursor: pointer;
      }

      .selectedRow {
        background-color: colors.$mmc-primary-010 !important;
      }

      .entityTypeIconContainer {
        width: 1rem;
        height: 1rem;
        flex-shrink: 0;
      }

      .entityTypeIcon {
        width: 0.5rem;
        height: 0.5rem;
      }
    }
  }
}
