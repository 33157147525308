@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .syncModalTitle {
        margin: 1rem 0 0.5rem;
      }
      .infoIcon {
        color: colors.$mmc-primary-100;
      }
      .warningIcon {
        color: colors.$mmc-yellow-100;
      }
      .syncModalContent {
        color: colors.$mmc-soft-slate;
        margin-left: 2rem;
      }
      .confirmationModal {
        :global(.ant-modal-body) {
          svg {
            color: colors.$mmc-primary;
          }
        }
      }
      .confirmationModalError {
        :global(.ant-modal-body) {
          svg {
            color: colors.$mmc-red-100;
          }
        }
      }
      .confirmationIcon {
        color: colors.$mmc-primary;
      }
      .whatAboutDeals {
        margin-top: 1rem;
        padding: 1rem;
        display: flex;
        gap: 1rem;
        border-radius: 0.25rem;
        background-color: colors.$mmc-soft-gray;
      }
      .whatAboutDealsTitle {
        font-size: 0.875rem;
        font-weight: 500;
        color: colors.$mmc-slate;
      }
      .whatAboutDealsIcon {
        color: colors.$mmc-red-100;
      }
      .whatAboutDealsText {
        font-size: 0.875rem;
        font-weight: 400;
        color: colors.$mmc-soft-slate;
      }
      .dealIcon {
        width: 1.25rem;
      }
      .errorTitle {
        color: colors.$mmc-slate;
        margin-bottom: 0.5rem;
      }
      .errorDescription {
        color: colors.$mmc-slate;
        font-size: 0.875rem;
      }
    }
  }
}
