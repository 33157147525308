@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        max-width: 750px;
      }
    }
  }
}
