@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      :global(.ant-progress-inner) {
        background-color: colors.$mmc-accent-gray;
      }

      :global(.ant-progress-bg) {
        background-color: colors.$mmc-primary-050;
      }

      :global(.ant-progress-success-bg) {
        background-color: colors.$mmc-primary-100;
      }

      .container {
        display: flex;
        align-items: center;
        gap: 1.5rem;
        width: 100%;
      }

      .progressTooltipOverlay {
        text-wrap: nowrap;
        max-width: fit-content;
      }

      .caption {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: colors.$mmc-soft-slate;
        text-wrap: nowrap;
        flex-shrink: 0;
      }
    }
  }
}
