@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .alert {
        margin-top: 1.5rem;
        :global(.ant-modal-content) {
          width: 32.5rem;
        }
        :global(.anticon) {
          color: colors.$mmc-yellow-100;
        }
      }

      .error {
        :global(.anticon) {
          color: colors.$mmc-red-100;
        }
      }

      .alertWrapper {
        //To make this alert visible in front of lead finder popover
        z-index: 1031;
      }

      .container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        align-items: flex-start;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .remaining {
        background-color: colors.$mmc-soft-gray;
        border-radius: 0.25rem;
        padding: 0.25rem 0.5rem;
        font-weight: 700;
      }

      .warn {
        background-color: colors.$mmc-yellow-010;
      }

      .noSearches {
        background-color: colors.$mmc-red-010;
      }
    }
  }
}
