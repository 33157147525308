@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        padding: 0.5rem 0.75rem 0.25rem;
        background-color: colors.$mmc-soft-gray;
        border: 1px solid colors.$mmc-soft-gray;
        cursor: pointer;
        border-radius: 0.25rem;
        display: flex;
        justify-content: space-between;
        min-height: 5rem;
        & > *:not(:last-child) {
          margin-right: 0.75rem;
        }
        &:hover {
          background-color: colors.$mmc-primary-005;
        }
        &:focus {
          border: 1px solid colors.$mmc-primary-010;
        }
      }
      .unread {
        background-color: colors.$mmc-primary-005;
        &:hover {
          background-color: colors.$mmc-primary-010;
        }
      }
      .iconWrapper {
        padding-top: 0.25rem;
      }
      .mainPartWrapper {
        flex: 1;
      }
      .actionType {
        width: 2rem;
        height: 2rem;
      }
      .iconContainer {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid colors.$mmc-accent-gray;
        background-color: colors.$mmc-white;
      }
      .icon {
        color: colors.$mmc-disabled-gray;
      }
      .grayIcon {
        color: colors.$mmc-soft-slate;
      }
      .iconContainerDanger {
        border: 1px solid colors.$mmc-red-025;
        background-color: colors.$mmc-red-010;
        svg {
          color: colors.$mmc-red-100;
        }
      }
      .iconContainerWarning {
        border: 1px solid colors.$mmc-yellow-025;
        background-color: colors.$mmc-yellow-010;
        svg {
          color: colors.$mmc-yellow-100;
        }
      }
      .iconContainerSuccess {
        border: 1px solid colors.$mmc-green-025;
        background-color: colors.$mmc-green-010;
        svg {
          color: colors.$mmc-green-100;
        }
      }
      .title {
        color: colors.$mmc-slate;
        font-size: 0.875rem;
        font-weight: 500;
      }
      .description {
        color: colors.$mmc-soft-slate;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.375rem;
      }
      .date {
        color: colors.$mmc-disabled-gray;
        font-weight: 400;
        margin-top: layout.$spacer-xs;
        font-size: 0.75rem;
      }
      .mentionWrapper {
        font-weight: 400;
      }
      .mention {
        background-color: inherit;
        color: colors.$mmc-slate;
        padding: 0;
        border-radius: 0;
        font-weight: 600;
      }
    }
  }
}
