@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
        flex: 1;
        overflow: hidden;
      }

      .subTitle {
        font-size: 0.875rem;
        font-weight: 400;
        color: colors.$mmc-soft-slate;
      }

      .modal {
        z-index: 1035; // we need to cover at lease activity annotation modal
        height: 80vh;

        & :global(.ant-modal-content) {
          height: 100%;
        }

        :global(.ant-modal-body) {
          height: 100%;
        }
      }

      .divider {
        margin: 0;
        border-color: colors.$mmc-primary-010;
      }

      .confirmation {
        width: 16rem;
      }
    }
  }
}
