@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .clearQueue {
        color: colors.$mmc-red-100;
      }

      .confirmDescription {
        margin-top: 0.5rem;
      }
    }
  }
}
