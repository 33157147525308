@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 12.5rem auto;
        row-gap: 0.625rem;
      }

      .showAll {
        align-self: flex-start;
        padding: 0;
      }

      .name {
        align-self: center;
        color: colors.$mmc-soft-slate;
      }

      .link {
        justify-self: flex-start;
        padding: 0;
      }
    }
  }
}
