@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .title {
        font-size: 1.25rem;
        font-weight: 500;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: colors.$mmc-slate;
        margin-right: 1.5rem;
      }

      .dateAssignee {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;
      }
    }
  }
}
