@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .activityModalWrapper,
      .activitiesModalWrapper {
        z-index: 1030;
      }

      .activityModalWrapper {
        overflow: hidden;
      }

      .activityModal {
        :global(.ant-modal-body) {
          padding: 1.25rem;
        }
      }
    }
  }
}
