@use "../../styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: colors.$mmc-soft-slate;
        gap: 0.25rem;
      }

      .separator {
        display: inline-block;
        height: 1.375em;
        width: 1px;
        background-color: colors.$mmc-accent-gray;
      }
    }
  }
}
