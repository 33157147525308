@use "../../styles/colors" as *;

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        display: flex;
      }

      .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        max-width: 100%;
        flex: 1;
      }

      .tag {
        max-width: 100%;
        text-align: left;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        word-break: break-word;
        margin: 0;
      }

      .completedTooltipWrapper {
        display: flex;
        flex-direction: column;
      }

      .completedLabel {
        font-size: 0.75rem;
        font-weight: bold;
        color: $mmc-soft-slate;
      }

      .date {
        font-size: 0.75rem;
        font-weight: 400;
        color: $mmc-soft-slate;
      }

      .durationWrapper {
        display: flex;
        flex-direction: column;
        margin-bottom: 0.75rem;
      }

      .checkIn,
      .sortFieldMessage {
        display: flex;
        align-items: center;
        font-size: 0.75rem;
        font-weight: 400;
        color: $mmc-primary;
        margin-top: 0.2rem;

        svg {
          margin-right: 0.325rem;
        }

        &.notVerified {
          color: $mmc-disabled-gray;
        }
      }

      .checkInOverlay {
        width: 23rem;
      }

      .checkInContainer {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        color: $mmc-soft-slate;
      }

      .checkInTitle {
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: 1.5rem;
        margin-top: 0.5rem;
        color: $mmc-slate;
      }

      .checkInLabel {
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.375rem;
      }

      .checkInReliabilityReasonsContainer {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }

      .checkInReliabilityReason {
        display: flex;
        gap: 0.5rem;
        align-items: flex-start;
      }

      .checkInReliabilityReasonIcon {
        margin-top: 0.25rem;
        color: $mmc-primary-100;
      }

      .checkInUnreliabilityReasonIcon {
        margin-top: 0.25rem;
        color: $mmc-red-100;
      }

      .sortFieldMessage {
        color: $mmc-disabled-gray;
      }
    }
  }
}
