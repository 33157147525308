@use "styles/colors";
@use "styles/layout";

:global {
  body[class*="theme-"].theme {
    :local {
      .modal {
        :global(.ant-modal-body) {
          min-height: 6rem;

          padding: 0;
          overflow: hidden;
        }
      }

      .advancedFieldsSection {
        margin-top: layout.$spacer-s;
      }

      .deleteButton {
        &:global(.ant-btn) {
          background-color: colors.$mmc-red-010;
          color: colors.$mmc-red-100;
          border-color: colors.$mmc-red-100;
        }

        & > :global(.ant-btn):disabled {
          outline: none;
        }
      }

      .inaccessibleEntityAlert {
        margin-bottom: layout.$spacer-m;
      }

      .dataLossAlert {
        align-items: baseline;
        border: 0;
        border-radius: 0;
        margin-bottom: layout.$spacer-m;
      }

      .checkInVerifiedAlert {
        border: 0;
        align-items: baseline;
        margin-bottom: layout.$spacer-m;

        :global(.ant-alert-action) {
          margin-left: 0;
        }
      }

      .description {
        margin-top: 0.25rem;
        margin-bottom: 0;
      }

      .actionButton {
        padding: 0;
      }

      .chevron {
        color: colors.$mmc-slate;
      }

      .message {
        margin-bottom: 0;
      }

      .infoIcon {
        color: colors.$mmc-disabled-gray;
        margin-left: 0.5rem;
      }

      .createAnotherPopover {
        width: 14rem;
      }

      .actionDropdown {
        user-select: none;

        & :global(.ant-dropdown-menu-item-group-list) {
          margin-left: 0;
          margin-right: 0;
        }

        & :global(.ant-dropdown-menu-item-group-title) {
          color: colors.$mmc-slate;
          font-weight: 700;
          font-size: 0.875rem;
          max-width: 16rem;
        }

        & :global(.ant-dropdown-menu-item-only-child) {
          color: colors.$mmc-soft-slate;
        }
      }

      .confirmationModal {
        & :global(.ant-modal-body) {
          padding-top: 1.5rem;
        }
      }

      .formContainer {
        min-height: 0;
        display: flex;
        flex-direction: column;
      }

      .form {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
      }

      .content {
        padding: 0.5rem 1.25rem 1rem 1.25rem;
        overflow: hidden auto;
        overscroll-behavior: none;
      }

      .footer {
        background: colors.$mmc-soft-gray;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        gap: 0.5rem;
        padding: 1rem 1.5rem 1rem 1.25rem;
        width: 100%;
        user-select: none;

        .left {
          flex-grow: 1;
        }

        .right {
          align-items: flex-end;
          display: flex;
          flex-direction: column;
          flex-shrink: 1;
          flex-wrap: wrap;
          gap: 1rem;
          justify-content: space-between;

          .actions {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap-reverse;
            gap: 0.5rem;
            justify-content: flex-end;
          }

          :global(.ant-checkbox-wrapper) {
            color: colors.$mmc-soft-slate;
            flex-grow: 0;
            flex-shrink: 1;
            font-size: 0.875rem;

            & > span:last-child {
              padding-right: 0;
            }
          }
        }
      }

      .extra {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .option {
        :global(.ant-select-item-option-content) {
          display: flex;
          align-items: center;
        }
      }

      .select {
        :global(.ant-select-selection-item) {
          display: flex;
          align-items: center;
        }
      }

      .confirmOverlay {
        max-width: 20rem;

        & :global(.ant-popover-message-icon) {
          color: colors.$mmc-red-100;
        }
      }
    }
  }
}
