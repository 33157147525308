@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .alert {
        margin-top: 4rem;
        :global(.ant-modal-content) {
          width: 32.5rem;
        }
      }

      .container {
        display: flex;
        gap: 0.5rem;
      }

      .content {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
      }

      .bullets {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
      }

      .point {
        display: flex;
        align-items: center;
        gap: 0.5rem;
      }

      .title {
        font-size: 1.25rem;
        font-weight: 600;
        line-height: 1.75rem;
        color: colors.$mmc-slate;
      }

      .primary {
        color: colors.$mmc-primary-100;
      }

      .bottomText {
        color: colors.$mmc-soft-slate;
        font-style: italic;
      }

      .image {
        align-self: flex-start;
      }

      .upsellWrapper {
        z-index: 1030; // Above lead finder popover
      }
    }
  }
}
