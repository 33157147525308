@use "styles/layout";
@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .container {
        width: 100%;
        padding-left: 1.25rem; // to add some space between logo and menu
      }

      // top item used in submenu means that it is in an overflown menu
      :global(.ant-menu-submenu) .topItem {
        & > :global(.ant-menu-submenu-title) {
          margin-bottom: 0;
          &,
          &:hover,
          & > :global(.ant-menu-submenu-arrow),
          & > :global(.ant-menu-submenu-arrow):hover {
            color: colors.$mmc-slate;
          }
        }
        &:global(.ant-menu-item),
        &:global(.ant-menu-submenu) {
          & a {
            &,
            &:hover {
              color: colors.$mmc-slate;
            }
          }
          &:hover {
            background-color: colors.$mmc-soft-gray;
          }
        }
        &:global(.ant-menu-submenu-selected) {
          color: colors.$mmc-slate;
          background-color: colors.$mmc-primary-010;
        }
      }

      .menu:global(.ant-menu) {
        & > :global(.ant-menu-item),
        & > :global(.ant-menu-submenu) {
          border-bottom: none;
          top: 0;
          margin: 0;
          padding: 0;
          display: inline-flex;
          align-items: flex-end;
          height: 60px;
          cursor: pointer;

          &:global(.ant-menu-overflow-item-rest) {
            align-items: center;
            font-weight: 500;
            color: colors.$mmc-slate;
            width: 2rem;
            & > :global(.ant-menu-submenu-title) {
              height: 100%;
              width: 100%;
              display: flex;
              justify-content: center;
            }
          }

          & > :global(.ant-menu-submenu-title) {
            display: inline-flex;
          }

          &::after {
            border-bottom-width: 0;
          }

          &:hover {
            background-color: colors.$mmc-soft-gray;
            &::after {
              border-bottom-width: 0;
            }
          }
        }

        & > :global(.ant-menu-item-selected),
        & > :global(.ant-menu-submenu-selected) {
          &::after {
            border-bottom-width: 0;
          }
        }

        //background-color: colors.$mmc-white;
        width: calc(100% - 1.25rem);
        border-bottom: none;
      }

      .submenu {
        user-select: none;

        & :global(.ant-menu-item) {
          & a {
            &,
            &:hover {
              color: colors.$mmc-slate;
            }
          }
          &:hover {
            background-color: colors.$mmc-soft-gray;
          }
        }
      }

      @media screen and (max-width: layout.$breakpoint-desktop) {
        .container {
          padding-left: 0.75rem;
        }
        .menu {
          width: calc(100% - 0.75rem);
        }
      }
    }
  }
}
