@use "styles/colors";

:global {
  body[class*="theme-"].theme {
    :local {
      .textField {
        & > *:first-child {
          color: colors.$mmc-soft-slate;
        }
      }
    }
  }
}
